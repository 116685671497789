import React from 'react';
import logo from '../../../../@assets/LandingPageAssets/logo.png';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

const Header = ({ totalUsers = null, pageHeading = null }) => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <div className="font-face-regular">
            <div className={classes.header}>
                <div className={classes.logo}>
                    <img src={logo} className={classes.logoImage} onClick={() => history.push('/')} />
                </div>
                {pageHeading
                    ? (<div className={classes.pageHeadingColumn}>
                        <h1 className={classes.pageHeading}>{pageHeading}</h1>
                    </div>)
                    : null
                }
                {totalUsers
                    ? (<div className={classes.totalUsersColumn}>
                        <h1 className={classes.totalUsersText}>Total Users: <span
                            style={{color: '#79C6E7'}}>{totalUsers}</span></h1>
                    </div>)
                    : null
                }
            </div>
        </div>
    )
}

export default Header