import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import LegalStuff from "../../components/signup/screens/legalStuff";
import Introduction from "../../components/signup/screens/Introduction";
import SignupForm from "components/signup/screens/signupForm";
import Expertise from "components/signup/screens/expertise";
import Rate from "components/signup/screens/rate";
import Username from "components/signup/screens/username";
import ProfileImage from "components/signup/screens/image";
import Referal from "components/signup/screens/referal";
import AuthCheck from "@services/authCheck";
import Login from "components/login";
import ProfileInvite from "components/profile-invite";
import Home from "components/home";
import SeeAll from "components/seeAll";
import EditProfile from "components/editProfile";
import Profile from "components/profile";
import Notifications from "components/notifications";
import Availability from "components/availability";
import DynamicComponent from "components/dynamicComponent";
import { useDispatch } from "react-redux";
import * as BaseActions from "store/actions";
import referal from "components/signup/screens/referal";
import Quarters from "components/quarters";
import UserProfile from "components/userProfile";
import Search from "components/search";
import AllSearchProfiles from "components/allSearchProfiles";
import Payment from "components/payment";
import Invite from "components/invite";
import InviteHistory from "components/invite/history";
import AddPayment from "components/addPayment";
import Booking from "components/booking";
import VideoCall from "components/videoCall/customVideo";
import HostRating from "components/hostRating";
import Help from "components/help";
import HelpQuestions from "components/help/questions";
import ContactUs from "components/contactus";
import History from '../../components/history/index';
import Earnings from "components/Earnings";
import Balance from 'components/balance/index';
import AddBankAccount from 'components/AddBankAccount'
import TandC from "components/privacyAndpolicy";
import PrivacyPolicy from "components/privacyAndpolicy/privacyPolicy";
import TermsOfService from "components/privacyAndpolicy/termsOfService";
import LandingPage from "components/LandingPage/Home";
import Vote from "components/LandingPage/Vote";
import VerifyPage from "components/verify/VerifyPage";
import ForgotPassword from "components/login/ForgotPassword";
import ResetPassword from "components/login/ResetPassword";
import Leaderboard from "components/Leaderboard";
// these are special checks
const checkTc_pp = (data) => {
  if (data.tc_and_pp !== 1) {
    return true;
  } else {
    return false;
  }
};
const checkRate = (data) => {
  if (data.rate === -1) {
    return true;
  } else {
    return false;
  }
};
const checkExperties = (data) => {
  if (data.tags === null) {
    return true;
  } else {
    return false;
  }
};
//////////////////////////////////////////////
// const layoutCheck= console.log()
export default function Routes({ setNotification }) {
  const dispatch = useDispatch();
  const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
  const data = isLoggedIn?.data;
  const setData = (data) => {
    dispatch(BaseActions.updateUserData(data));
  };
  return (
    <>
      <Switch>
        <PrivateRoute setNotification={setNotification} path="/home" component={Home} exact setData={setData} />
        <PrivateRoute setNotification={setNotification} path="/contactus" component={ContactUs} exact setData={setData} />
        <PrivateRoute setNotification={setNotification} path="/help" component={Help} exact setData={setData} />
        <PrivateRoute setNotification={setNotification} path="/help/questions" component={HelpQuestions} exact setData={setData} />
        <PublicRoute path="/privacy" component={PrivacyPolicy} exact setData={setData} />
        <PublicRoute path="/terms" component={TermsOfService} exact setData={setData} />
        <PrivateRoute setNotification={setNotification} path="/tandc" component={TandC} exact setData={setData} />
        <PrivateRoute setNotification={setNotification}
          path="/rating"
          component={HostRating}
          exact
          setData={setData}
        />
        <PublicRoute
          path="/quartr/:appointment_id"
          component={VideoCall}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/resolving"
          component={() => <></>}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/booking"
          component={Booking}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/payment"
          component={Payment}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/invite"
          component={Invite}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/invite/history"
          component={InviteHistory}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/addPayment"
          component={AddPayment}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/seeAll/:param"
          exact
          component={SeeAll}
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/profilesintag"
          component={AllSearchProfiles}
          exact
          setData={setData}
        />
        <PublicRoute
          path="/userProfile/:id"
          component={UserProfile}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/search/:tab"
          component={Search}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/availability"
          component={Availability}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/profile"
          component={Profile}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/notifications"
          component={Notifications}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/editProfile"
          component={EditProfile}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/quarters"
          component={Quarters}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/history"
          component={History}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/earnings"
          component={Earnings}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/legalStuff"
          specialCheck={checkTc_pp}
          component={LegalStuff}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/expertise"
          specialCheck={checkExperties}
          component={Expertise}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/profile-invite/:id"
          // path="/profile-invite"
          component={ProfileInvite}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/signup"
          component={SignupForm}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/login"
          component={Login}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/forgotpassword"
          component={ForgotPassword}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/resetpassword"
          component={ResetPassword}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/"
          component={LandingPage}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/intro"
          component={Introduction}
          exact
          setData={setData}
        />
        <PublicRoute
          restricted={true}
          path="/verify"
          component={VerifyPage}
          exact
          setData={setData}
        />
        <PublicRoute
            restricted={true}
            path="/leaderboard"
            component={Leaderboard}
            exact
            setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/rate-refer"
          component={Rate}
          specialCheck={checkRate}
          setData={setData}
          exact
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/username"
          component={Username}
          // specialCheck={checkRate}
          setData={setData}
          exact
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/profile-image"
          component={ProfileImage}
          // specialCheck={checkRate}
          setData={setData}
          exact
        />
        <PrivateRoute
          setNotification={setNotification}
          path="/balance"
          component={Balance}
          exact
          setData={setData}
        />
        <PrivateRoute
          setNotification={setNotification}
          path='/add-bank-account'
          component={AddBankAccount}
          exact
          setData={setData}
        />
        <Redirect from='/search' to='/search/top' />
        <Route
          path="/landing"
          component={LandingPage}
          exact
        />
        <Route
          path="/vote"
          component={Vote}
          exact
        />
        <Route
          path="/verify"
          component={VerifyPage}
          exact
        />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  );
}
const PublicRoute = ({
  component: Component,
  restricted,
  setData,
  ...rest
}) => {
  const isLogin = () => {
    const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
    const token = localStorage.getItem("JWTtoken");
    if (isLoggedIn && token) {
      setData(isLoggedIn.data);
      return true;
    } else {
      return false;
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted && window.location.pathname !== "/verify" ? (
          <Redirect to="/home" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
// special check can be any kind of check it will be a method which return true / false boolean value for our condition evaluation.
const PrivateRoute = ({
  component: Component,
  specialCheck,// specialCheck is on basis we can manipulate our navigation that user fulfill our condition to visit this page otherwise we'll navigate back user to main home page.
  setData,
  setNotification,
  ...rest
}) => {
  const isLogin = () => {
    const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
    const token = localStorage.getItem("JWTtoken");
    if (isLoggedIn && token) {
      if (specialCheck) {
        // console.log({ setNotification })
        // setNotification();
        if (specialCheck(isLoggedIn.data)) {
          setData(isLoggedIn.data);
          return true;
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component setNotification={setNotification} {...props} /> : <Redirect to="/" />
      }
    />
  );
};
// 404 page not found component
const PageNotFound = withRouter((props) => (
  <>
    <h1 style={{ color: "white" }}>404 page not found</h1>{" "}
    <button onClick={() => props.history.push("/home")}>go to main page</button>
  </>
));
